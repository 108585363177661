import { Link } from "gatsby";
import React from "react";
import BekMenu from "../components/bek-menu";
import Layout from "../components/layout";
import Seo from "../components/seo";

export const Head = () => {
  return (
    <Seo
      title="鍼灸メニュー"
      description="体の痛み, 心の悩み、美容、鎮痛治療全般 初診4,900円等"
    />
  );
};

const IkkadouMenuPage = () => {
  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "一花堂メニュー",
      link: "/ikkadou-menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "アクセス・お問い合わせ",
      link: "/ikkadou#access",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
  ];

  // ここでデータを定義するか、あるいはAPIから取得するかします
  const womenMenuItems = [
    {
      name: "顔脱毛",
      firstVisit: "全体:11,000〜\n 口周り:3300〜",
    },
    {
      name: "腕・足・脇",
      firstVisit: "11,000〜",
    },
    {
      name: "VIO全体",
      firstVisit: "11,000〜",
    },
    {
      name: "全身（顔・VIO・手首足首なし）",
      firstVisit: "16,500〜",
    },
    {
      name: "プレミアム（全身・顔・VIO）",
      firstVisit: "33,000〜",
    },
    {
      name: "フォトフェイシャル（シミ・たるみ）",
      firstVisit: "16,500〜",
    },
    {
      name: "学生コース（腕・足・脇）",
      firstVisit: "5,500〜",
    },
    {
      name: "バストアップ",
      firstVisit: "5,500〜",
    },
    {
      name: "追加一部",
      firstVisit: "5,500〜",
    },
  ];
  const menMenuItems = [
    {
      name: "ヒゲ",
      firstVisit: "11,000〜",
    },
    {
      name: "各部位",
      firstVisit: "5,500〜",
    },
    {
      name: "腕・足・脇",
      firstVisit: "16,500〜",
    },
    {
      name: "VIO",
      firstVisit: "22,000〜",
    },
    {
      name: "全身（髭・VIOなし）",
      firstVisit: "27,500〜",
    },
    {
      name: "プレミアム（全身・顔・VIO）",
      firstVisit: "49,500〜",
    },
    {
      name: "学生コース（腕・足・脇）",
      firstVisit: "7,700〜",
    },
    {
      name: "追加一部位",
      firstVisit: "7,700〜",
    },
  ];

  return (
    <Layout navLinks={navLinks} color={"rgb(50, 100, 177, 0.2)"}>
      <div className="kamata-body">
        <div className="kamata-body-text">
          <div className="iikadou-menu-text">
            <h1>
              <small>鍼灸エステ</small> 一 花 堂 メニュー
            </h1>
          </div>
        </div>
        <BekMenu
          title={"レディース脱毛"}
          menuItems={womenMenuItems}
          color={"rgba(232, 7, 180, 1)"}
        />
        <BekMenu
          title={"メ ン ズ 脱毛"}
          menuItems={menMenuItems}
          color={"rgba(7, 16, 255, 1)"}
        />
        <div className="kamata-body-text">
          <div className="ikkadou-menu-body-text">
            <p>※マツエクについては、本院または美容館へお問い合わせください。</p>
            <p>※リンパについては、以下までお問い合わせください。</p>
          </div>
        </div>
        <div id="about" className="aboutusbox">
          <div className="aboutbody">
            <p>TEL: 080-7154-3738</p>
            <p>Email: este.soin@docomo.ne.jp</p>
            <p>担当:そあん元兼</p>
          </div>
        </div>

        <div className="to-top-box">
          <Link to="/ikkadou" className="menu-button">
            一花堂トップページへ
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default IkkadouMenuPage;
